.qwert .card {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.qwert  .text-center {
  text-align: center;
  margin-top: 20px;
}

.qwert  .thank-you-template {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}
.thank-you-div{
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  text-align: center;
  min-height: 5rem;
  padding: 1rem;
  max-width: 45rem;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.thank-you-card{
  border: 2px solid #00a65a;
  border-radius: 15px;
  padding: 0.9rem;
}
.qwert .thank-you-template h3 {
  color: #00a65a;
}

.qwert  .thank-you-template p {
  font-size: 18px;
  color: #333;
}
.qwert{
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/images/bg.png");
  background-position: 57%;
  background-repeat: no-repeat;
  background-size: cover;
}
.qwert .card {
  background-color: #4d4d4d4d;
  border: initial!important;
}
.qwert h5 {
  font-size: 1.4rem;
  color: #fff;
  padding-bottom: 18px;
    border-bottom: 1px solid;
}

@media screen and (min-width: 786px) {
  .qwert h5 {
    font-size: 1.58rem;
  }
}
.qwert p {
  font-weight: 600;
  color: #fff;
  font-size: 1.6rem;
  padding-bottom: 2px;
}
.qwert .progress {
  height: 3px;
}
.qwert .form-control {
 color: #212529!important;
    background-color: #fff!important;
    border: unset;
    padding: 0 10px;
    border-bottom: 1px solid #fff;
    border-radius: 5px;
}
.qwert .form-control:focus {
  border-color: #00a65a !important;;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #00a65a !important;;
}
.qwert input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.qwert .radio-option {
  color: #fff;
}
.qwert .radio-option-extra {
  color: #fff;
}
.qwert .btn-primary{
   background-color: #fff;
   border: 0px;
   border-radius: 0px;
   width: 100px;
   color: #000;
}
.qwert .progress-bar {
  background-color: #00a65a;
}
.qwert .btn-primary.focus,.qwert .btn-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 255, 255, 0.5);
}
.qwert .radio-option {
  padding: 0 5px;
  /* margin-left: 23px; */
}
.qwert .radio-option input[type=radio] {
  margin-top: 8px;
}
.qwert .radio-option-extra {
  padding: 0 20px;
  /* margin-left: 23px; */
}
.qwert .radio-option-extra input[type=radio] {
  margin-top: 5px;
}
.qwert label {
  display: inline-block;
  margin-bottom: .5rem;
  font-size: 16px;
}
.qwert .img-btn img{
  width:100px;
  height:50px;
}
.qwert .img-btn > input{
  display:none
}
.qwert .img-btn > img{
  cursor:pointer;
  border:5px solid transparent;
}
.qwert .img-btn > input:checked + img{
  border-color: black;
  border-radius:10px;
}
.qwert .row .col-sm-6{
  margin-top: 2px;    
  border: 1px solid #fff;
  background-color: #1515157d;  
  padding: 2px;
} 
.qwert .row .col-sm-6 div{
  padding-left: 10px;
} 
.qwert .row .col-sm-6 .form-label{
  color: #fff;
  font-size: 20px;
  border-bottom: 1px solid #fff;
}
.qwert .row .col-sm-6 .radio-option {
  margin-left: 6px;
}
.qwert .row .col-sm-6 .radio-option-extra {
  margin-left: 0;
}
.qwert .row .col-sm-6 div div label{
  padding-left: 10px;;
}
.qwert .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00a65a;
  border-color: #00a65a;
}
.qwert .col-md-4 input[type='radio']:after {
  width: 15px;
  height: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  border-radius: 50%;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.qwert .col-md-4 input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.qwert .col-md-4 .radio-option input[type=radio] {
  margin-top: 0px;
}
.custom-radio input[type="radio"] {
  display: none;
}
.radio-option-name label {
 text-align: center;
    min-width: 8rem;
    display: inline-block;
    padding: 5px;
  border-color: white;
  background-color: white; 
      transition: border-color 0.3s ease-in-out;
    border-radius: 8px;
      color: #000;

}

.radio-option-extra-name label {
 text-align: center;
    min-width: 7rem;
    display: inline-block;
    padding: 2px;
  border-color: white;
  background-color: white; 
      transition: border-color 0.3s ease-in-out;
    border-radius: 8px;
      color: #000;

}

.radio-option-name label:hover,
.radio-option-name.active label  {

   border-color: white;
  background-color: #00a65a; 
  color: white;

}
.radio-option-extra-name label:hover,
.radio-option-extra-name.active label  {

   border-color: white;
  background-color: #00a65a; 
  color: white;

}

.custom-radio-extra-div{
  width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    /* padding:5px; */
}
.custom-radio-div{
  width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding:5px;
}

.image-radio-row{
  padding: 0px 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.radio-option-image label {
 text-align: center;
    min-width: 8rem;
    padding: 5px;
 border-color: white;
  background-color: white; 
    color: #000;

      transition: border-color 0.3s ease-in-out;
    border-radius: 8px;
}
.radio-option-image label:hover,
.radio-option-image.active label  {

   border-color: white;
  background-color: #00a65a; 
  color: white;

}

.radio-image{
  width: 15rem;
    height: 8rem;
    object-fit: fill;
    vertical-align: middle;
    border-style: none;
}
/* event css add */
a.btn.btn_event {
  background: green;
  color: #fff !important;
  font-size: 1.4rem !important;
  border-radius: 7%;
}
.main-page-con a {
  text-transform: none !important;
}

.row{
  overflow-x: hidden;
}

.container-fluid.qwert{
  overflow: hidden !important;
}

.qwert .card{
  overflow: hidden !important;
}

.imgH{
  margin-bottom: 7rem !important;
}