.col-7 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.col-12 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}
.col-5 {
  padding-right: 8px !important;
  padding-left: 12px !important;
}
.plan {
  cursor: pointer;
  width: 100%;
}
.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.plans .plan .plan-content {
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid #8686866b;
  border-radius: 10px;
  transition: box-shadow 0.4s;
  position: relative;
}
.plans .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}
.plans .plan .plan-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #252F42;
}
.container .title {
  font-size: 20px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252F42;
  margin-bottom: 20px;
}
.plans .plan .plan-details p {
  color: #646A79;
  font-size: 14px;
  line-height: 18px;
}
/* .plans {
  padding: 2px 14px 0px 14px;
} */
p.title_price {
  font-size: 13px;
  margin-bottom: 6px;
}
.plans .plan input[type="radio"]:checked + .plan-content {
  border: 2px solid #28A745;
  background: #ffffff00;
  transition: ease-in 0.3s;
  box-shadow: #28a7455e 0px 7px 29px 0px;
}
/* inspiration */
.inspiration {
  font-size: 12px;
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
  font-weight: 300;
}
.inspiration a {
  color: #666;
}
.hide {
  display: none;
}
.myDIV:hover + .hide {
  display: block;
  color: red;
}
.content_new {
  padding: 12px;
  text-align: center;
}
@keyframes bottomToTop {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.modal-bottom-to-top .modal-dialog {
  animation: bottomToTop 0.4s ease-out;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.lab-modal-body h1 {
  font-size: 34px;
  font-weight: 500;
}
.lab-modal-body .desc {
  margin: 0 0 1.62rem 0;
  line-height: 1.62;
  font-weight: 300;
  font-size: 18px;
  color: #666;
}
.box_img img {
  width: 340px;
  height: 100%;
}
p.desc_new_c {
  background: #80808026;
  padding: 20px;
  margin-top: 30px;
  font-size: 14px;
}
.modal.show .modal-dialog {
  transform: none;
  margin: 0;
  padding: 0;
}
span.bolder-f {
  font-weight: 600;
  color: #0FB991;
}
#lab-slide-bottom-popup .modal-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 12px 12px 0px 0px;
  outline: 0;
  bottom: 0;
}
@media (min-width: 576px)
{
  .modal-dialog {
    max-width: 390px !important;
    margin: 1.75rem auto !important;
  }
  div#lab-slide-bottom-popup-2 {
    position: relative !important;
  }
}
#lab-slide-bottom-popup .btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  box-sizing: initial;
  height: 1em;
  opacity: var(--bs-btn-close-opacity);
  padding: .25em;
  width: 1em;
}
#lab-slide-bottom-popup-2 .btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  box-sizing: initial;
  height: 1em;
  opacity: var(--bs-btn-close-opacity);
  padding: .25em;
  width: 1em;
}
@import url('https://fonts.googleapis.com/css2?family=Croissant+One&family=Sansita+Swashed:wght@300..900&display=swap');
.croissant-one-regular {
  font-family: "Croissant One", serif;
  font-weight: 400;
  font-style: normal;
}
h1.spcl_offer {
  font-family: "Croissant One", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
}
/* count */
/* TIMER STYLES */
.timeContainer {
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 20px 0px;
}
.timeContainer .wrapper {
  width: 800px;
  padding: 14px;
  z-index: 99999;
}
.wrapper {
  display: flex;
  gap: 10px;
  max-width: 180px;
}
.timeContainer .wrapper div {
  color: #ccc;
  text-align: center;
  width: 100%;
  height: 80px;
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.timeContainer .wrapper div:first-child{
  background: #00A3D0;
  color: white;
  font-size: 12px;
}
.timeContainer .wrapper div:nth-child(2){
  background: #00A3D0;
  color: white;
  font-size: 12px;
}
.timeContainer .wrapper div:nth-child(3){
  background: #1C1C1C;
  color: #fff;
  font-size: 12px;
}
.timeContainer .wrapper div:last-child{
  background: #1C1C1C;
  color: #fff;
  font-size: 12px;
}
.timeContainer .wrapper div h2 {
  font-size: 40px;
  margin: 0px;
}
.circleDiv {
  position: fixed;
  z-index: 1;
  top: calc(61% - 203px);
  left: calc(-24% + 357px);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 4px dashed #00A3D0;
  animation: rotatingCircle infinite linear 60s;
}
@keyframes rotatingCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* MOUSE TRAIL STYLE */
#cursor {
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  pointer-events: none;
  user-select: none;
  backdrop-filter: invert(100%);
}
p.offer_screen {
  background: #80808026;
  padding: 20px;
  margin-top: 30px;
  font-size: 18px;
}
.plan-content.new_card {
  background-color: #FFFFFF !important;
  color: #060000;
  text-align: left;
}
p.title_price.new_399 {
  font-size: 14px;
  margin: 0;
  color: #000;
}
.modal-content {
  outline: 0;
  bottom: 0;
  border-radius: 12px 12px 0px 0px !important;
  width: 100% !important;
  margin-left: -1px !important;
  position: fixed;
}
.col-6.offer_price .price.showprice.d-flex.add_price {
  font-size: 16px;
}
/* popup card css */
section.price-main.mt-3.add_popupop {
  font-size: 14px;
  margin: 16px;
  margin-top: 0px;
  border: 1px solid #0D0D0D;
  padding-top: 12px;
  border-radius: 5px;
}
.add_popupop .heading.mt-0 {
  color: #343434;
}
.price-main.add_popupop p.price_descn {
  color: #000 !important;
}
.col-12.text-left {
  text-align: left;
}
.using_CONTENT {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 5px 0px 10px 0px;
}
.using_CONTENT p {
  margin: 0;
  font-size: 14px;
}
.price.showprice.d-flex.add_price {
  color: #000;
}
div#lab-slide-bottom-popup-2 {
  position: absolute;
  min-height: 64vh;
  bottom: 0;
}
.cool_modal {
  top: 18px !important;
  position: fixed !important;
}

.circleDiv {
  position: absolute;
  z-index: 1;
  top: 4px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 4px dashed #00A3D0;
  animation: rotatingCircle infinite linear 60s;
  /* right: 10%; */
  bottom: 0;
  left: 57%;
}
.timeContainer {
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 20px 0px;
  position: relative;
}