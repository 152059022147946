/* Loader.css */
.loader-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .loader-heading {
    font-size: 24px;
    margin-top: 20px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .progressbar-container {
    width: 200px;
    height: 200px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loader-hdng{
     font-size: 22px;
     margin-top: 30px;
     font-weight: bold;
     color: #3BAD4B;
     padding: 0 10px;
     text-align: center;
}
.loader-parent{
    height: 100vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public/images/bg.png");
    background-position: 57%;
    background-repeat: no-repeat;
    background-size: cover;
}
.CircularProgressbar .CircularProgressbar-text {
    fill: #000000;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
    font-weight: 700;
}
.CircularProgressbar .CircularProgressbar-path {
    stroke: #3BAD4B;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}
p.infotitle_ai {
  margin-bottom: -2px;
}
.bluprint-ai {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: -2px;
  background-color: #2f2e2e;
  border-radius: 0px 2px 4px 4px;
  position: relative;
  top: -10px;
  color: white;
  padding: 4px 10px;
}















.main-page-con{
  background-color: #000;
}
.heading{
  color: #fff;
  width: 100%;
}

.heading h2{
  font-size: 20px;
  text-align: center;
}
.heading h3{
  font-size: 20px;
}
.price-main p{
 color: #fff;
}

.main-page-con .expire {
  display: none;
}

.main-page-con .carousel-indicators [data-bs-target] {
  width: 13px;
  height: 3px;
}

.main-page-con .carousel-indicators {
  bottom: -18px;
}

.main-page-con .carousel-control-next-icon,
.main-page-con .carousel-control-prev-icon {
  width: 1rem;
  height: 2rem;
}

.main-page-con .plan_detail .price .btn_pay {
  background-color: #14f7c1;
  color: var(--blue-color);
  position: unset;
  width: 100%;
}

.main-page-con .price-main {
  font-size: 14px;
  margin: 16px;
  margin-top: 0px;
  border: 1px solid #14f7c1;
  padding-top: 12px;
  border-radius: 5px;
}

.main-page-con .price-main-g {
  border: 1px solid #dec06d;
}

.main-page-con .plan_detail .price .btn_pay {
  background-color: #14f7c1;
  color: var(--blue-color);
  position: unset;
  width: 100%;
  font-weight: 900;
}

.main-page-con sup {
  top: 0px;
}

.main-page-con .plan_detail .plan_img {
  border-radius: 0px;
}

.main-page-con .advantages-bx {
  border-radius: 6px;
  padding: 8px 7px;
  min-height: 143px;
  margin-bottom: 20px;
  background: #4a4a4a;
}

.main-page-con .advantages h6 {
  font-size: 17px;
  margin-top: 12px;
  line-height: 1.5;
  color: #fff;
}

.main-page-con .advantages img {
  width: 44px;
  height: 44px;
  background: #fff;
  padding: 8px;
  border-radius: 50%;
  margin-top: 14px;
}

.main-page-con .bg-secondary {
  background-color: #4a4a4a !important;
}

.main-page-con .accordion-item {
  background-color: unset;
  border: 1px solid rgba(0, 0, 0, .125);
}

.main-page-con .accordion-button {
  font-size: 12px;
}

.main-page-con .accordion-header {
  margin-bottom: 0px;
}

.main-page-con .accordion-body {
  padding: 1rem 1.25rem;
  background: #EBEBEB;
  color: #000;
  margin-bottom: 7px;
}

.main-page-con .accordion-flush .accordion-item .accordion-button {
  border-radius: 6px;
}

.main-page-con .testimonial-main {
  background: #4a4a4a;
  padding: 13px 42px;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  color: #fff;
}

.main-page-con .testimonial-main p {
  font-size: 12px;
  line-height: 21px;
}

.main-page-con .carousel-control-next {
  right: 9px;
}

.main-page-con .carousel-control-prev {
  left: 16px;
}

.main-page-con .accolades .row .col-9 p b {
  font-size: 12px;
  color: #000;
}

.main-page-con .accolades .row .col-9 p {
  font-size: 12px;
  color: #000 !important;
  line-height: 1.2;
  margin-bottom: 0px;
}

.main-page-con .carousel-indicators [data-bs-target] {
  background-color: #14f7c1;
}

.main-page-con .plan_detail .btn-request {
  background-color: #14f7c1;
  color: #060606;
}

.main-page-con .plan_detail .price sup {
  background-color: #14f7c1;
  color: var(--blue-color);
  margin-left: 0px;
  padding: 0 4px;
  font-size: 12px;
}

.main-page-con a {
  text-transform: lowercase;
}




.main-page-con .gallery-container h1 {
  text-align: center;
  margin-top: 50px;
  font-family: 'Droid Sans', sans-serif;
  font-weight: bold;
}

.main-page-con .gallery-container p.page-description {
  text-align: center;
  margin: 25px auto;
  font-size: 18px;
  color: #999;
}

.main-page-con .tz-gallery {
  padding: 10px;
}

.main-page-con .tz-gallery img {
  height: 100%
}

.main-page-con .tz-gallery .row>div {
  padding: 2px;
}

.main-page-con .tz-gallery .lightbox img {
  width: 100%;
  border-radius: 0;
  position: relative;
}


.main-page-con .tz-gallery .lightbox:hover:after,
.main-page-con .tz-gallery .lightbox:hover:before {
  opacity: 1;
}

.main-page-con .baguetteBox-button {
  background-color: transparent !important;
}

.main-page-con .timer-top {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  height: 48px;
  width: 100%;
  position: relative;
  z-index: 999999;
}

.main-page-con .timer-to-inner {
  background-color: #14f7c1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  height: 48px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
}

.main-page-con .timer-top p {
  margin-bottom: 0px !important;
  color: #000f !important;
  font-size: 20px;
  padding: 1px 0px 5px 4px;
  border-radius: 5px;
  font-size: 33px;
}

.main-page-con .back-errow {
  font-size: 43px;
  line-height: 0;
  margin-top: -10px;
  cursor: pointer;
}

.main-page-con .modal-footer {
  font-size: 14px;
  color: red;
  cursor: pointer;
  text-align: center !important;
  justify-content: center !important;
  text-transform: capitalize;
  padding-top: 8px !important;
  border-top: 1px solid #000000;

}


.main-page-con .modal-content {
  width: 90%;
  margin: auto;
  background-color: #14f7c1;
}

.main-page-con .fade:not(.show) {
  opacity: 0.9 !important;
}


button.accordion-button.collapsed {
  background: #3b3b3b;
  width: 100%!important;
  text-align: left;
  margin: auto;
}

button.accordion-button{
  background: #3b3b3b;
  width: 100%;
  text-align: left;
  margin: auto;
}
.step-form button {
  /* background-color:  #4a4a4a!important; */
    background-color:  white!important;
color: #000;
  border-radius: 5px!important;
}
.btn.disabled, .btn:disabled {
    opacity: .90 !important;;
}
.main-slider .carousel-indicators button {
  background-color: #14f7c1!important;
  padding: 0px;
  margin-left: 5px;
}
button.btn.btn-sm.btn_pay.w-100 {
  background-color: #14f7c1!important;
  font-weight: bold;
}
.price.showprice{
  color: #14f7c1;
}
.price.expire{
  color: #14f7c1;
}
span.visually-hidden {
  display: none;
}
p.text-sm.text-center {
  color: #fff;
  font-size: 12px;
  padding: 10px;
}

a{
   color: #14f7c1!important;
}
.col-12.text-center.mb-1, .col-12.text-center.mb-2.text-white,.col-12.text-center.text-white {
  font-size: 11px;
}
.main-section{
  background-color: #000;
}
.pay-button{
  width: 100%;
  padding: 10px;
  background-color: #14f7c1;
  position: fixed;
  bottom: 0px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.close-mdl {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-weight: bold;
  background-color: #000;
  color: #fff;
  padding: 5px 10px 9px;
  font-size: 16px;
  font-weight: bold;
}
.glry-img{
  display: flex;
}
.price.showprice.d-flex {
  align-items: center;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
}
.prsnt{
  background-color: #14f7c1;
  color: #000;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 3px;
  margin: 0px 8px;
}

/* add dropdown css */
.header {
  height: 44px;
  background-color: #484848;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
}
.dropdown-flex {
  display: flex;
  flex-direction: column;
  background-color: #2f2f2f;
  z-index: 111;
  padding: 10px;
  transform: translate3d(-15px, 77px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  width: 110px;
}
a.drop-down-ai {
  position: absolute;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 14px;
}
.dropdown-flex a {
  color: #fff !important;
  cursor: pointer;
}

/* slide and modal css start */
.item_ai_slider  .slid-con {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414'/%3E%3C/svg%3E");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  box-sizing: initial;
  height: 1em;
  opacity: var(--bs-btn-close-opacity);
  padding: .25em;
  width: 1em;
}
.item_ai_slider .carousel.slide {
  min-height: 225px;
}
.item_ai_slider .carousel-indicators button {
  background-color: #14F7C1!important;
  padding: 0px;
  margin-left: 5px;
}
.item_ai_slider .carousel-control-next-icon {
  filter: invert(1);
}
.item_ai_slider .carousel-control-prev {
  filter: invert(1);
}
.main-slider  .carousel.slide {
  margin-top: 8px;
}

.tag {
  display: inline-block;
  background-color: #ffc800;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 28px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  position: relative;
  width: 104px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  color: black !important;
}
p.super_tag {
  display: inline-block;
  background-color: #fe3efe;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 28px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  position: relative;
  width: 104px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  color: white !important;
}
p.tag_front {
  display: inline-block;
  background-color: #ffc107;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 28px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  position: relative;
  width: 104px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  left: 0px;
  color: rgb(0, 0, 0) !important;
}
/* language page css start */
.language_select {
  text-align: center;
  margin-top: 3%;
  color: #fff;
  font-size: 13px;
}
.language_select a {
  color: #fff !important;
  cursor: pointer;
  text-decoration: none !important;
}
/* offer-box designe start */
.offer_flex {
  display: f;
  display: block;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.col-6_ai.offer_price {
  margin-top: 6px;
}
.price_f_1 {
  font-size: 18px;
}
.price_f_2 {
  font-size: 18px;
  color: #ffc107;
}
.price_f_3 {
  font-size: 18px;
}
.price_f_4 {
  font-size: 18px;
  color: #fb62fb;
}
.accordion-body a {
  color: darkblue !important;
}
.col-7_ai {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card_new-ai {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #00000091;
  padding: 18px;
  margin-right: 15px;
  margin-left: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 4px #000000;
  margin-bottom: 7rem !important;
}