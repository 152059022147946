/* StepForm.css */
/* .step-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.step {
  width: 600px;;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #00000087;
  margin-bottom: 7rem !important;
}
.step-form input{
      background-color: #fff!important;
  /* background-color: unset; */
  border-color: green;
  border-radius: 20px;
    color: #000 !important;

}


.step-form .css-13cymwt-control {
  /* background-color: unset; */
        background-color:#fff!important;

  border-color: green;
  border-radius: 20px 0px 0px 20px;
  height: 30px!important;
  color: #000 !important;
  min-height: 46px!important;
}
.css-qbdosj-Input {
  color: #000 !important;
}
.css-1dimb5e-singleValue {
  color: #000 !important;
  padding: 0 1.5px ;
}
.step-form button {
  background-color: #4CAF50!important;
  border-radius: 20px!important;
}
.nmbr{
  border-radius: 0px 20px 20px 0px!important;
}
.step {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* color: grey; */
  color: white;
}
.css-1xc3v61-indicatorContainer {
  padding: 8px 5px !important;
  color: #000!important;  
}
.css-1u9des2-indicatorSeparator {
  background-color: white!important;
}


button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.col-8.pl-0{
  position: relative;
}
.icon-inp{
    position: absolute;
    top: 8px;
    right: 30px;
    /* background-color: red; */
    color: #fff;
}
.step h2 {
  font-size: 19px;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
}
.main-bdy{
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/images/bg.png");
  background-position: 57%;
  background-repeat: no-repeat;
  background-size: cover;
}

.css-qbdosj-Input {
margin: 1px !important;
}
@media screen and (max-width: 650px) and (min-width:320px){
  .step-form {
    width: 100%;
  }
}
